type AppServiceKey = ('vininvoice' | 'MOQUI_SESSION_TOKEN');

export class AppService {

  static readonly app = 'iam-viet-invoice-ng.v1.0.0';

  static set(key: AppServiceKey, value: string): void {
    localStorage.setItem(`${this.app}_${key}`, value);
  }

  static get(key: AppServiceKey): string {
    return localStorage.getItem(`${this.app}_${key}`) || '';
  }

  static remove(key: AppServiceKey): void {
    localStorage.removeItem(`${this.app}_${key}`);
  }

  static removeAll(): void {
    this.remove('MOQUI_SESSION_TOKEN');
  }

}

type UserServiceKey = ('SETTING' | 'ROUTER_STATE' | 'PRIVILEGE' | 'user-logo' | 'tax-policy-seen');

export class UserService {

  static readonly app = AppService.app;

  static setUser(user: any): void {
    AppService.set(`${this.app}_user` as any, JSON.stringify(user));
  }

  static getUser(): any {
    return JSON.parse(AppService.get(`${this.app}_user` as any) || '{}');
  }

  static set(key: UserServiceKey, value: string): void {
    AppService.set(`${this.app}_${key}_${this.getUser().partyId}` as any, value);
  }

  static get(key: UserServiceKey): string {
    return AppService.get(`${this.app}_${key}_${this.getUser().partyId}` as any);
  }

  static remove(key: UserServiceKey): void {
    AppService.remove(`${this.app}_${key}_${this.getUser().partyId}` as any);
  }

  static removeAll(): void {
    this.remove('PRIVILEGE');
  }

}
