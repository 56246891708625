<p-dialog header="Ký điện tử không thành công" [(visible)]="pluginNotFound" [style]="{width: '580px'}" [resizable]="false">
  <div class="p-grid">
    <div class="p-col-2 p-text-center">
      <img src="/assets/images/usb-not-connected-icon.png" alt="">
    </div>
    <div class="p-col-10">
      <p><b>Hãy kiểm tra các trường hợp dưới đây để khắc phục vấn đề của bạn</b></p>
      <p><b>1.</b> Bạn chưa cài đặt phần mềm ký điện tử VININVOICE hoặc bản cài của bạn cần cập nhật</p>
      <p class="p-pl-4">Hãy cài đặt phần mềm ký điện tử VININVOICE để tiếp tục.</p>
      <p class="p-pl-4"><a href="/assets/tools/vininvoice.rar"><i class="pi pi-download"></i> Tải bộ cài</a></p>

      <p><b>2.</b> Bạn đã cài đặt công cụ <b>Ký số điện tử VININVOICE</b></p>
      <p class="p-pl-4">Hãy mở công cụ <b>Ký số điện tử VININVOICE</b> trước khi ký điện tử</p>
      <p class="p-pl-4"><a href="vininvoice:123"><i class="pi pi-info-circle"></i> Mở phần mềm ký điện tử VININVOICE</a></p>
    </div>
  </div>
  <p-footer>
    <p-button label="{{ 'Close'|translate }}" styleClass="p-button-raised" (onClick)="pluginNotFound=false"></p-button>
  </p-footer>
</p-dialog>
