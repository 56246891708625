import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {EntitiesComponent} from './entities/entities.component';

const routes: Routes = [
  {path: '', loadChildren: () => import('./pages/login/login.module').then(mod => mod.LoginModule)},
  {
    path: 'app',
    component: EntitiesComponent,
    children: [
      {path: 'home', loadChildren: () => import('./entities/home/home.module').then(mod => mod.HomeModule)}
    ]
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
